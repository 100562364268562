<template>
  <div style="float:left;width:100%">
    <Refresh :reType="type"></Refresh>
    <van-row
      class="main"
      style="border:1px solid #d9d4d4;height:3rem;border-left:0px;border-right:0px;background-color:#fff"
    >
      <van-col span="14" class="head">
        <van-cell @click="showPopup" style="width:100%">{{
          timer(timeDate)
        }}</van-cell>
      </van-col>
      <van-col class="text" span="5">
        <van-button type="success" size="small" @click="frontDate"
          >前一天</van-button
        >
      </van-col>
      <van-col class="text" span="5">
        <van-button type="success" size="small" @click="behindDate"
          >后一天</van-button
        >
      </van-col>
    </van-row>
    <div
      class="messagePrompt"
      v-if="!list.data.length > 0 && httpType == false"
    >
      <p style="text-align:center">
        <van-icon name="warning" size="7rem" color="rgb(9 131 236)" />
      </p>
      <p style="text-align:center;font-size:1.3rem">
        系统提示：
      </p>
      <p style="text-align:center;font-size:0.8rem;color:rgb(123 132 141)">
        未找到你想要的数据！
      </p>
    </div>
    <div class="messagePrompt" v-if="httpType">
      <p style="text-align:center">
        <van-icon name="clear" size="7rem" color="#ff4d4f" />
      </p>
      <p style="text-align:center;font-size:1.3rem">
        系统提示：
      </p>
      <p style="text-align:center;font-size:0.8rem;color:rgb(123 132 141)">
        网络异常，请稍后再试！
      </p>
    </div>
    <div
      class="recordDetailsBottom"
      style="margin-top:15px"
      v-if="list.data.length > 0"
    >
      <van-row v-if="list">
        <van-col span="24">
          <van-row style="height:2rem;width:93%;margin:auto;line-height: 2rem;">
            <van-col
              span="4"
              style="text-align:left;font-size:0.8rem;color:rgb(158 157 157)"
              >合&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;计</van-col
            >
            <van-col span="20" class="headText">{{ list.quantity }}件</van-col>
          </van-row>
        </van-col>
        <van-col span="24">
          <van-row style="width:93%;margin:auto;line-height: 2rem;">
            <van-col
              span="4"
              style="text-align:center;font-size:0.8rem;color:rgb(158 157 157)"
            >
            </van-col>
            <van-col
              span="20"
              class="headText"
              style="word-wrap:break-word; word-break:break-all;"
            >
              ￥{{ list.amount }}</van-col
            >
          </van-row>
        </van-col>
      </van-row>
    </div>
    <div
      class="recordDetails"
      :key="index"
      v-for="(item, index) in list.data"
      @click="querys(item)"
    >
      <div class="recordDetailsTop">
        <van-row style="word-wrap:break-word; word-break:break-all;">
          <van-col span="24">
            <van-row
              style="width:93%;margin:auto;line-height: 2rem;"
              :key="index1"
              v-for="(item1, index1) in item.process"
            >
              <van-col
                span="7"
                style="text-align:left;font-size:0.8rem;color:rgb(158 157 157);word-wrap:break-word; word-break:break-all;"
                >{{ item.orderNumber }}</van-col
              >
              <van-col span="17" class="headText">{{ item1 }}</van-col>
            </van-row>
          </van-col>
          <van-col span="24">
            <van-row
              style="height:2rem;width:93%;margin:auto;line-height: 2rem;"
              :key="index1"
              v-for="(item1, index1) in item.list"
            >
              <van-col
                span="20"
                style="text-align:left;font-size:0.8rem;color:rgb(158 157 157)"
                >{{
                  `${item1.technologyName}(扫描日期：${item1.date})`
                }}</van-col
              >
              <van-col span="4" class="headText">{{
                item1.amount.toFixed(2)
              }}</van-col>
            </van-row>
          </van-col>
          <van-col span="24">
            <van-row style="width:93%;margin:auto;line-height: 2rem;">
              <van-col
                span="6"
                style="text-align:left;font-size:0.8rem;color:rgb(158 157 157)"
              >
                订单备注</van-col
              >
              <van-col
                span="18"
                class="headText"
                style="word-wrap:break-word; word-break:break-all;"
              >
                {{ item.remark }}</van-col
              >
            </van-row>
          </van-col>
        </van-row>
      </div>
    </div>

    <div style="float:left;width:100%;height:4rem"></div>
    <van-overlay :show="show" @click="show = false">
      <div class="Popup" :style="show ? 'bottom: 0rem;' : 'bottom: -19rem;'">
        <van-datetime-picker
          v-model="currentDate"
          type="date"
          title="选择年月日"
          @confirm="confirmTimer"
          @cancel="cancelTimer"
        />
      </div>
    </van-overlay>
    <van-overlay
      :show="showDialog"
      style="display: flex;align-items: center;justify-content: center;"
    >
      <div id="formData">
        <div
          style="width:100%;text-align:center;height:40px;line-height:43px;border-bottom:1px solid #c7c3c3;color:rgb(113 112 112)"
        >
          查看
        </div>
        <div class="form_main">
          <div style="width:90%">
            <van-row style="margin-top:0.3rem">
              <van-col
                span="6"
                style="text-align:center;font-size:0.9rem;color:rgb(135 135 135)"
                >订单编号</van-col
              >
              <van-col
                span="18"
                style="text-align:right;font-size:0.9rem;color: red;"
              >
                {{ !orderData.orderNumber ? "" : orderData.orderNumber
                }}<br v-if="orderData.senderNumber" />
                {{ !orderData.senderNumber ? "" : orderData.senderNumber
                }}<br v-if="orderData.internalOrderNumber" />
                {{
                  !orderData.internalOrderNumber
                    ? ""
                    : orderData.internalOrderNumber
                }}
              </van-col>

              <van-col
                span="6"
                style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135);"
                >工序信息</van-col
              >
              <van-col
                span="18"
                style="text-align:right;margin-top:0.5rem;font-size:0.9rem;"
              >
                <div
                  v-for="(item, index) in !orderData.productInfo
                    ? 0
                    : orderData.productInfo"
                  :key="index"
                >
                  {{
                    item.quantity > 1
                      ? item.processInfo + "*" + item.quantity
                      : item.processInfo
                  }}
                </div>
              </van-col>
              <van-col
                span="6"
                style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
                >客户名称</van-col
              >
              <van-col
                span="18"
                style="text-align:right;margin-top:0.5rem;font-size:0.9rem"
              >
                {{
                  !orderData.customerName ? "" : orderData.customerName
                }}</van-col
              >
              <van-col
                span="6"
                style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
                >下单日期</van-col
              >
              <van-col
                span="18"
                style="text-align:right;margin-top:0.5rem;font-size:0.9rem"
              >
                {{ !orderData.orderDate ? "" : orderData.orderDate }}</van-col
              >
              <van-col
                span="6"
                style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
                >审核日期</van-col
              >
              <van-col
                span="18"
                style="text-align:right;margin-top:0.5rem;font-size:0.9rem"
              >
                {{ !orderData.checkDate ? "" : orderData.checkDate }}1</van-col
              >
              <van-col
                span="6"
                style="text-align:center;margin-top:0.5rem;font-size:0.9rem;color:rgb(135 135 135)"
                >订单备注</van-col
              >
              <van-col
                span="18"
                style="text-align:right;margin-top:0.5rem;font-size:0.9rem;word-wrap:break-word; word-break:break-all;"
                >{{
                  !orderData.sellerRemark ? "" : orderData.sellerRemark
                }}</van-col
              >
            </van-row>
          </div>
        </div>
        <div class="form_bottom">
          <van-button
            @click="returns"
            style="width:100%; background: #fff;border-color:#fff;color: rgb(79, 192, 141);"
            >确定</van-button
          >
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
import { Grid, GridItem } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { Image as VanImage } from "vant";
import { Row, Col } from "vant";
import { Field } from "vant";
import { Button } from "vant";
import { DatetimePicker } from "vant";
import { Overlay } from "vant";
import { Icon } from "vant";
import { dodailySalary, getOrderView } from "@/config/_api";
import Refresh from "../../refresh/index.vue";
import { GetWorkloadDisplay } from "@/config/_api";
export default {
  components: {
    [Overlay.name]: Overlay,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    [Tabbar.name]: Tabbar,
    [TabbarItem.name]: TabbarItem,
    [VanImage.name]: VanImage,
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Field.name]: Field,
    [Button.name]: Button,
    [DatetimePicker.name]: DatetimePicker,
    Refresh,
  },

  data() {
    return {
      show: false,
      currentDate: new Date(),
      timeDate: new Date(),
      js: 100,
      zj: 200,
      list: {
        data: [],
        amount: 0,
        quantity: 0,
      },
      orderData: {},
      showDialog: false,
      httpType: false,
    };
  },
  created() {
    this.ajaxApi();
    this.workloadDisplay();
  },
  methods: {
    async workloadDisplay() {
      let { data } = await GetWorkloadDisplay();
      this.type = data;
    },
    async querys(row) {
      console.log(row);
      let { data } = await getOrderView({ id: row.orderId });
      data.data.productInfo = JSON.parse(data.data.productInfo);
      this.orderData = data.data;
      this.showDialog = true;
    },
    returns() {
      this.showDialog = false;
    },
    frontDate() {
      let timer = new Date(this.timeDate);
      var oneDay = 1000 * 60 * 60 * 24; //一天的毫秒数
      var before = timer.getTime() - oneDay; //计算前一天的毫秒数
      this.timeDate = this.timer(before);
      this.ajaxApi();
    },
    behindDate() {
      let timer = new Date(this.timeDate);
      var oneDay = 1000 * 60 * 60 * 24; //一天的毫秒数
      var before = timer.getTime() + oneDay; //计算前一天的毫秒数
      this.timeDate = before;
      this.ajaxApi();
    },
    confirmTimer() {
      this.show = false;
      this.timeDate = this.currentDate;
      this.ajaxApi();
    },
    cancelTimer() {
      this.show = false;
    },
    timer(data) {
      let timer = new Date(data);
      let year = timer.getFullYear();
      let month =
        timer.getMonth() + 1 < 10
          ? "0" + (timer.getMonth() + 1)
          : timer.getMonth() + 1;
      let date = timer.getDate() < 10 ? "0" + timer.getDate() : timer.getDate();
      let dataTimer = year + "-" + month + "-" + date;
      return dataTimer;
    },
    showPopup() {
      this.show = !this.show;
    },
    async ajaxApi() {
      let { data } = await dodailySalary({
        day: this.timer(this.timeDate),
      });
      if (data && data.code == 1) {
        this.list = data.data;
      } else {
        this.httpType = true;
      }
    },
  },
};
</script>
<style scoped>
.text {
  display: flex;
  align-items: center;
  justify-content: center;
}
.head {
  display: flex;
  align-items: center;
  text-indent: 10px;
}
.headText {
  text-align: right;
  font-size: 0.8rem;
}
.Popup {
  width: 100%;
  height: 19rem;
  overflow: hidden;
  position: fixed;

  z-index: 10;
  transition: all 0.2s linear;
}
.messagePrompt {
  width: 100%;
  height: 15rem;
}
.recordDetails {
  width: 100%;

  margin-top: 1rem;
}
.recordDetailsTop {
  width: 100%;

  border-top: 1px dashed #d9d4d4;
  border-bottom: 1px dashed #d9d4d4;
  background-color: #fff;
}
.recordDetailsBottom {
  width: 100%;

  border-top: 1px dashed #d9d4d4;
  border-bottom: 1px dashed #d9d4d4;
  background-color: #fff;
}
#formData {
  width: 90%;
  height: 75%;
  background-color: #fff;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
}
.form_bottom {
  width: 100%;
  height: 9%;
  position: absolute;
  border-top: 1px solid #c7c3c3;
  bottom: 0;
}
.form_main {
  width: 100%;
  height: 80%;
  padding: 5%;
}
</style>
